.resourceButton {
  position: relative;
  border: 1px solid var(--bs-gray-200);
  transition:
    border-color 200ms ease,
    background-color 200ms ease;
  width: 100%;
  //height: 50px;
  flex-grow: 1;
  background-color: var(--background-color, var(--bs-white));
  overflow: hidden;

  &:hover {
    border-color: var(--hover-border-color, var(--bs-gray-400));

    :global(span.deleteHover) {
      display: block;
      position: absolute;
      right: 0;
      padding: 5px;
      border-radius: 50%;
    }
  }

  &.selected {
    border-color: var(--bs-gray-400);
    background-color: white !important;
  }

  :global(span.deleteHover) {
    display: none;
  }
}

.placeholder {
  flex-grow: 1;
  width: 100%;
  height: 70px;
}
