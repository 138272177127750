// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import 'zindex';

// CUSTOM COLOURS
:root {
  --bs-info-rgb: 64, 75, 82;

  // --bs-blue: #0d6efd;
  // --bs-indigo: #6610f2;
  // --bs-purple: #6f42c1;
  // --bs-pink: #d63384;
  // --bs-red: #dc3545;
  // --bs-orange: #fd7e14;
  // --bs-yellow: #ffc107;
  // --bs-green: #198754;
  // --bs-teal: #20c997;
  // --bs-cyan: #0dcaf0;
  // --bs-black: #000;
  // --bs-white: #fff;
  // --bs-gray: #6c757d;
  // --bs-gray-dark: #343a40;
  --bs-gray-100: #f9fafe;
  --bs-gray-200: #e9ecef;
  --bs-gray-200-rgb: 233, 236, 239;
  --bs-gray-300: #ced4da;
  --bs-gray-350: #e9e6e6;
  --bs-gray-400: #b3bcbc;
  --bs-gray-500: #7c8a8a;
  --bs-gray-600: #404b52;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #434a54;
  // --bs-primary: #0d6efd;
  // --bs-secondary: #6c757d;
  // --bs-success: #198754;
  // --bs-info: #0dcaf0;
  --bs-danger: #cc0000;
  // --bs-light: #f8f9fa;
  --bs-dark: #f1f1f1;
  // --bs-primary-rgb: 13, 110, 253;
  // --bs-secondary-rgb: 108, 117, 125;
  // --bs-success-rgb: 25, 135, 84;
  // --bs-info-rgb: 13, 202, 240;
  --bs-danger-rgb: 204, 0, 0;
  // --bs-light-rgb: 248, 249, 250;
  // --bs-dark-rgb: 33, 37, 41;
  // --bs-white-rgb: 255, 255, 255;
  // --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 89, 114, 116;
  // --bs-body-bg-rgb: 255, 255, 255;
  // --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  // --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  // --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  // --bs-body-font-family: var(--bs-font-sans-serif);
  // --bs-body-font-size: 1rem;
  // --bs-body-font-weight: 400;
  // --bs-body-line-height: 1.5;
  --bs-body-color: #597274;
  // --bs-body-bg: #fff;
  // --bs-border-width: 1px;
  // --bs-border-style: solid;
  // --bs-border-color: #dee2e6;
  // --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 1.5rem;
  --bs-border-radius-xl: 2rem;
  --bs-border-radius-sm: 0.5rem;
  // --bs-border-radius-lg: 0.5rem;
  // --bs-border-radius-xl: 1rem;
  // --bs-border-radius-2xl: 2rem;
  // --bs-border-radius-pill: 50rem;
  // --bs-link-color: #0d6efd;
  // --bs-link-hover-color: #0a58ca;
  // --bs-code-color: #d63384;
  // --bs-highlight-bg: #fff3cd;
  --bs-body-fs: 1.4rem;
  --bs-primary-rgba25: rgba(var(--bs-primary-rgb), 0.25);
  --bs-primary-rgba50: rgba(var(--bs-primary-rgb), 0.5);
  --bs-primary-rgba75: rgba(var(--bs-primary-rgb), 0.75);

  // Mui overrides (calendar and time pickers)
  --mui-palette-primary-main: var(--bs-primary) !important;
  --mui-palette-primary-dark: var(--bs-primary) !important;
}
// CUSTOM COLOURS

// CUSTOM MODAL SIZE
.modal-sm {
  --bs-modal-width: 400px;
}
.modal-md {
  --bs-modal-width: 600px;
}
.modal-lg {
  --bs-modal-width: 900px;
}
.modal-footer-global {
  border-top: 0;

  .btn,
  button {
    min-width: 100px;
    font-size: 1.2rem;
    border-radius: 50px;
    padding: 8px 25px;

    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3) !important;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3) !important;
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3) !important;
  }
}
// CUSTOM MODAL SIZE

.bg-gray-1 {
  background-color: var(--bs-gray-100);
}

.border-gray-1 {
  border-color: var(--bs-gray-100) !important;
}

.border-gray-2 {
  border-color: var(--bs-gray-200) !important;
}

.border-gray-3 {
  border-color: var(--bs-gray-300) !important;
}

.border-gray-4 {
  border-color: var(--bs-gray-400) !important;
}

.border-gray-5 {
  border-color: var(--bs-gray-500) !important;
}

.bg-gray-2 {
  background-color: var(--bs-gray-200);
}

.bg-gray-3 {
  background-color: var(--bs-gray-300) !important;
}

.bg-gray-350 {
  background-color: var(--bs-gray-350) !important;
}

.bg-gray-4 {
  background-color: var(--bs-gray-400) !important;
}

.bg-gray-5 {
  background-color: var(--bs-gray-500) !important;
}

.bg-gray-6 {
  background-color: var(--bs-gray-600);
}

.bg-gray-7 {
  background-color: var(--bs-gray-700);
}

.bg-gray-8 {
  background-color: var(--bs-gray-800);
}

.bg-gray-9 {
  background-color: var(--bs-gray-900) !important;
}

.bg-orange {
  background-color: #ffbf4b;
}

.bg-available {
  background-color: #ccf1d4 !important;
}

.bg-unavailable {
  background-color: #ffe7e6 !important;
}

.bg-partial-available {
  background-color: #ffebcd !important;
}

.text-partial-available {
  color: #b8860b !important;
}

.text-light {
  color: var(--bs-gray-400) !important;
  font-weight: 300;
}

.text-black {
  color: black;
}

.text-gray-1 {
  color: var(--bs-gray-100) !important;
}

.text-gray-2 {
  color: var(--bs-gray-200) !important;
}

.text-gray-3 {
  color: var(--bs-gray-300) !important;
}

.text-gray-4 {
  color: var(--bs-gray-400) !important;
}

.text-gray-5 {
  color: var(--bs-gray-500) !important;
}

.text-gray-6 {
  color: var(--bs-gray-600) !important;
}

.text-gray-7 {
  color: var(--bs-gray-700) !important;
}

.text-gray-8 {
  color: var(--bs-gray-800) !important;
}

.text-dark {
  color: var(--bs-gray-900) !important;
}

.text-shadow {
  text-shadow: #000 1px 1px 5px;
}

.text-xero {
  color: #18b3d5;
}

// BUTTONS OVERRIDERS
// a:hover {
//   color: var(--bs-gray-900);
// }
.btn {
  border: none;
  padding: 0.75rem 1.25rem;
  border-radius: 2rem;

  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05) !important;
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05) !important;
}
.btn-light {
  --bs-btn-color: white;
  --bs-btn-bg: var(--bs-gray-600);
  --bs-btn-border-color: var(--bs-gray-600);
  --bs-btn-disabled-border-color: var(--bs-gray-600);
  --bs-btn-hover-color: white;
  --bs-btn-hover-bg: var(--bs-gray-700);
  --bs-btn-hover-border-color: var(--bs-gray-700);
  --bs-btn-active-color: white;
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
}

.btn-info {
  --bs-btn-color: var(--bs-gray-600);
  --bs-btn-bg: var(--bs-gray-200);
  --bs-btn-border-color: var(--bs-gray-200);
  --bs-btn-hover-color: var(--bs-gray-600);
  --bs-btn-hover-bg: var(--bs-gray-400);
  --bs-btn-hover-border-color: var(--bs-gray-400);
  --bs-btn-active-color: var(--bs-gray-600);
  --bs-btn-active-bg: var(--bs-gray-400);
  --bs-btn-active-border-color: var(--bs-gray-400);
  --bs-btn-disabled-color: var(--bs-gray-600);
  --bs-btn-disabled-bg: var(--bs-gray-200);
  --bs-btn-disabled-border-color: var(--bs-gray-200);
}

.btn-gray {
  --bs-btn-color: var(--bs-gray-600);
  --bs-btn-bg: var(--bs-gray-200);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-gray-600);
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-active-bg: var(--bs-gray-600);
  --bs-btn-disabled-color: var(--bs-white);
  --bs-btn-disabled-bg: var(--bs-gray-300);

  --bs-btn-border-color: var(--bs-gray-300);
  --bs-btn-active-border-color: var(--bs-gray-600);
}

.btn-gray-600 {
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bs-gray-600);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-gray-500);
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-active-bg: var(--bs-gray-600);
  --bs-btn-disabled-color: var(--bs-white);
  --bs-btn-disabled-bg: var(--bs-gray-200);

  --bs-btn-border-color: var(--bs-gray-600);
  --bs-btn-active-border-color: var(--bs-gray-600);
}

.btn-white {
  --bs-btn-color: var(--bs-gray-600);
  --bs-btn-bg: #fff;
  --bs-btn-hover-color: var(--bs-gray-900);
  --bs-btn-hover-bg: var(--bs-gray-200);
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: var(--bs-gray-900);
  --bs-btn-active-bg: var(--bs-gray-200);
  --bs-btn-disabled-color: var(--bs-gray-900);
  --bs-btn-disabled-bg: var(--bs-gray-100);

  --bs-btn-border-color: var(--bs-gray-300);
  --bs-btn-active-border-color: var(--bs-gray-900);
  --bs-btn-hover-border-color: var(--bs-gray-300);
  --bs-btn-active-border-color: var(--bs-gray-300);
}

.btn-outline-gray {
  --bs-btn-color: var(--bs-gray-600);
  --bs-btn-bg: var(--bs-gray-200);
  --bs-btn-border-color: var(--bs-gray-300);
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-gray-600);
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-disabled-color: var(--bs-white);
  --bs-btn-disabled-bg: var(--bs-gray-200);
}

.btn-outlook {
  --bs-btn-color: #0078d4;
  --bs-btn-bg: var(--bs-white);
  --bs-btn-border-color: #0078d4;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0078d4;
  --bs-btn-active-border-color: #0078d4;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #50d9ff;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-disabled-color: var(--bs-white);
  --bs-btn-disabled-bg: var(--bs-gray-200);
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #cc0000;
  --bs-btn-border-color: #cc0000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #cc0000;
  --bs-btn-disabled-border-color: #cc0000;
}

//.btn-warning {
//  --bs-btn-color: var(--bs-white);
//  --bs-btn-hover-color: var(--bs-white);
//  --bs-btn-active-color: var(--bs-white);
//  --bs-btn-disabled-color: var(--bs-white);
//}
// BUTTONS OVERRIDERS

.rounded-sm {
  border-radius: var(--bs-border-radius-sm) !important;
}

:root {
  font-size: 62.5%;
}

html,
body {
  background: #f9fafe;
  padding: 0;
  margin: 0;
  font-family: 'Poppings', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: var(--bs-body-fs);
  --bs-body-color: var(--bs-gray-600);
  height: 100%;

  .main-header {
    position: absolute;
    padding-top: 75px;
    width: 100%;

    div {
      height: 20px;
    }
  }
}

.rosterelf .main-header {
  background: linear-gradient(45deg, rgba(51, 160, 68, 1) 0%, rgba(51, 160, 68, 0.7) 100%);
}

.clubshr .main-header {
  background: linear-gradient(45deg, rgba(1, 103, 146, 1) 0%, rgba(1, 103, 146, 0.8) 100%);
}

@include media-breakpoint-down(md) {
  #root {
    padding-bottom: 55px;
  }
}

button {
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
}

.form-floating > label {
  padding-top: 0.5rem;

  &:after {
    background-color: transparent !important;
  }
}

.form-floating > .form-select {
  padding-left: 0.75rem;
}

.form-control:focus {
  color: var(--bs-gray-600);
  border-color: var(--bs-gray-400);
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.08);
}

.form-check {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  //color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

small,
.small {
  font-size: 1rem;
}

textarea {
  resize: none;
}

.form-floating > textarea.form-control:not(:placeholder-shown) {
  padding-top: 1.8rem;
  min-height: calc(5.5rem + 10px);
}

.form-label,
button,
p,
span {
  margin: 0;
}

.fit-contain {
  object-fit: contain !important;
}

.fit-cover {
  object-fit: cover !important;
}

.position-left {
  object-position: left !important;
}

.tooltip {
  --bs-tooltip-bg: var(--bs-primary);
  z-index: calc(var(--zIndex-globalLoader) + 1);

  &.danger {
    --bs-tooltip-bg: var(--bs-danger);
  }

  a {
    color: white;
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }
}

.bg-danger-light {
  background-color: #ff9999;
}

.text-danger-light {
  color: #ff9999;
}

.min-h-100 {
  min-height: 100% !important;
}

.h-inherit {
  height: inherit;
}

input[type='date'],
input[type='time'] {
  position: relative;
  //border: 0;
  color: inherit;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    color: transparent;
    background: transparent;
  }

  &::-webkit-clear-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    appearance: none;
    margin: -10px;
  }

  &::-webkit-datetime-edit-ampm-field {
    display: none;
  }
}
select,
input {
  background-color: transparent !important;
}
.input-group-text {
  border: none !important;
  background-color: RGBA(0, 0, 0, 0.1) !important;
}
.input-group {
  // overflow: hidden;
  border-radius: var(--bs-border-radius) !important;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.2) !important;
}
label,
input[type='checkbox'] {
  cursor: pointer;
}

input[type='checkbox'] {
  flex-shrink: 0;
}

.table-lg > :not(caption) > * > * {
  padding: 1rem 1rem;
}

.form-label,
.form-select,
.form-control {
  font-size: var(--bs-body-fs);
  color: var(--bs-gray-600);

  &::placeholder {
    font-style: italic;
  }
}

.form-control-md {
  font-size: 1.3rem;
}

.tab-content > .active {
  display: flex;
}

*[disabled] {
  pointer-events: unset !important;
}
*[disabled]:hover {
  cursor: not-allowed !important;
}
.btn-xero {
  background-color: #1fc0e7;
  color: white;

  &:focus,
  &:hover {
    background-color: #1aa3c4;
    border-color: #1588a4;
    color: white;
  }
}

.nav-tabs .nav-link {
  background-color: var(--bs-gray-600);
  color: var(--bs-white);
  border: transparent;
  margin-right: 0.5rem;
}

.nav-tabs .nav-link.active {
  background-color: var(--bs-white);
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  min-height: calc(3.5rem + 10px);
}

.input-group-text {
  background-color: var(--bs-gray-200);
  color: var(--bs-gray-600);
}

#root {
  height: 100%;
}

main section {
  flex-grow: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.show-placeholder {
  input {
    padding-top: 1.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  ::-webkit-input-placeholder {
    color: #ccc !important;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: #ccc !important;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #ccc !important;
  }

  :-ms-input-placeholder {
    color: #ccc !important;
  }

  input::placeholder {
    color: #ccc !important;
  }

  label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }
}

.fs-13 {
  font-size: 1.3rem;
}

.bg-custom-avatar {
  background-color: #9ba8a8;
}

.vertical-rl {
  writing-mode: vertical-rl;
}

@import 'rs-picker';

.lessThan2Hours .MuiSlider-valueLabelOpen {
  display: none !important;
}

// Gleen AI Chat
#helix-chat-container {
  display: none;

  &.opened > div {
    height: 500px;
    width: 360px;
  }

  > div {
    left: unset;
    right: 20px;
    padding-bottom: 10px;

    > a {
      display: none;
    }
  }

  > div[role='button'] {
    span {
      display: none;
    }
  }

  button.close-gleen {
    position: absolute;
    top: -5px;
    left: 0;
    background-color: white;
    color: var(--bs-primary);
    font-weight: bold;
    width: 20px;
    height: 20px;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.bg-dark-transparent {
  background: RGBA(0, 0, 0, 0.3) !important;
}

.shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05) !important;
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05) !important;
}

.tab-gradient {
  background: linear-gradient(0deg, rgba(64, 75, 82, 0.1) 0%, rgba(255, 255, 255, 1) 100%);
}
